import React, { useEffect, useState } from 'react';
import './Navbar.css';
import CartSidebar from '../CartSidebar/CartSidebar';

const defaultLogo = '/assets/logo.webp'; // Path to the default logo

const Navbar = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [cartCount, setCartCount] = useState(0);

    useEffect(() => {
        updateCartCount();

        const handleStorageChange = (event) => {
            if (event.key === 'cart') {
                updateCartCount();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const updateCartCount = () => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        setCartCount(cart.length);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    return (
        <>
            <div className='check' id='touchit'>
                <div className='left'>
                    <i className="ri-menu-line" style={{ color: '#fff' }}></i>
                    <img src={defaultLogo} alt='logo' style={{ height: "25px", width: "90px" }} />
                </div>

                <div className='right'>
                    <img src='/assets/heart.svg' alt='heart Icon' style={{ height: '24px', width: '24px', display: 'none' }} />
                    <div style={{ position: 'relative' }}>
                        <i className="ri-shopping-cart-2-fill" style={{ color: '#fff', fontSize: '18px' }} onClick={toggleSidebar}></i>
                        {cartCount > 0 && (
                            <span className="navspan">
                                {cartCount}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <CartSidebar isOpen={isSidebarOpen} closeNav={closeSidebar} />
        </>
    );
};

export default Navbar;
