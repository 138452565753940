import React from 'react';
import Nav from './Nav/Nav';
import Images from './Images/Images';

const ProductDetails = () => {
  return (
    <>
      <Nav />
      <Images />
    </>
  );
};

export default ProductDetails;
