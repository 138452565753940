import React, { useEffect, useState } from 'react'; 
import { useNavigate } from 'react-router-dom';
import CartSidebar from '../../CartSidebar/CartSidebar';
import './Nav.css';

// Default logo URL
const defaultLogo = '/assets/logo.webp';

const Nav = () => {
    const [logo, setLogo] = useState(defaultLogo);
    const [cartCount, setCartCount] = useState(0); // State to manage cart count
    const [isSidebarOpen, setSidebarOpen] = useState(false); // State to manage sidebar visibility

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch logo from local storage or use default
        const savedLogo = localStorage.getItem('logo');
        if (savedLogo) {
            setLogo(savedLogo);
        } else {
            setLogo(defaultLogo);
        }

        // Retrieve cart count from local storage
        updateCartCount();

        // Listen for storage changes to update cart count
        const handleStorageChange = (event) => {
            if (event.key === 'cart') {
                updateCartCount(); // Update cart count when `cart` changes
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    // Function to update the cart count
    const updateCartCount = () => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        setCartCount(cart.length);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    // Function to close sidebar
    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    return (
        <>
            <div className='check'>
                <div className='left'>
                    <svg
                        onClick={() => navigate('/')} // Navigate to root on click
                        width="25" 
                        height="25" 
                        viewBox="0 0 20 20" 
                        fill="none" 
                        xmlns="http://www.w3.org/2000/svg" 
                        mt="2" 
                        iconsize="24" 
                        className="sc-gswNZR ffVWIj"
                        style={{ cursor: 'pointer' }} // Add cursor pointer to indicate it's clickable
                    >
                        <path 
                            d="M13.7461 2.31408C13.5687 2.113 13.3277 2 13.0765 2C12.8252 2 12.5843 2.113 12.4068 2.31408L6.27783 9.24294C5.90739 9.66174 5.90739 10.3382 6.27783 10.757L12.4068 17.6859C12.7773 18.1047 13.3757 18.1047 13.7461 17.6859C14.1166 17.2671 14.0511 16.5166 13.7461 16.1718L8.29154 9.99462L13.7461 3.82817C13.9684 3.57691 14.1071 2.72213 13.7461 2.31408Z" 
                            fill="#fff"
                        />
                    </svg>
                    <img src={logo} alt='logo' style={{height:"25px",width:"90px"}} />
                </div>

                <div className='right'>
                    <img src='/assets/heart.svg' alt='heart Icon' style={{ height: '24px', width: '24px',display:'none' }} />
                    <div style={{ position: 'relative' }}>
                        <i className="ri-shopping-cart-2-fill" style={{color:'#fff',fontSize:'18px'}} onClick={toggleSidebar}></i>             
                        {cartCount > 0 && (
                            <span className="navspan">
                                {cartCount}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <CartSidebar isOpen={isSidebarOpen} closeNav={closeSidebar} />
        </>
    );
}

export default Nav;
