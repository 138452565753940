import React, { useEffect, useState } from 'react';
import './Banner.css';
import Carousel from './Carousels/Carousel';
import API_URL from '../../../apiconfig';

const defaultBannerLink = 'https://images.meesho.com/images/widgets/Y8HIH/2f53o.gif'; // Default fallback banner

const Banner = () => {
  const [banner1Link, setBanner1Link] = useState(() => {
    // Use cached banner from localStorage if available, otherwise use the default banner
    return localStorage.getItem('banner1Link') || defaultBannerLink;
  });

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await fetch(`${API_URL}/homebanner/banner1_link`);

        // Ensure the response is OK before parsing JSON
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        // Check if data is not empty and different from the current state
        if (data.length > 0 && data[0] !== banner1Link) {
          setBanner1Link(data[0]);
          localStorage.setItem('banner1Link', data[0]); // Update localStorage with the new banner
        }
      } catch (error) {
        console.error('Error fetching banner data:', error);
      }
    };

    // Fetch the banner only if the localStorage doesn't have a valid link
    const storedBannerLink = localStorage.getItem('banner1Link');
    if (!storedBannerLink || storedBannerLink === defaultBannerLink) {
      fetchBanner();
    }
  }, [banner1Link]); // Added banner1Link as a dependency

  return (
    <div>
      <img 
        src={banner1Link} 
        alt="Banner" 
        className="w-100 mb-2" 
        style={{ width: '375px', height: '38px', display: 'none', objectFit: 'cover' }} 
      />
      <Carousel />
    </div>
  );
}

export default Banner;
