import React, { useEffect, useState, useCallback } from 'react';
import './CartSidebar.css';
import { Link } from 'react-router-dom';
// import API_URL from '../../apiconfig';

const CartSidebar = ({ isOpen, closeNav }) => {
  const [cartProducts, setCartProducts] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);

  const fetchCartProducts = useCallback(() => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];
    console.log(cartItems);
  
    if (cartItems.length > 0) {
      const storedProducts = JSON.parse(localStorage.getItem('products')) || []; // Get products from local storage
  
      // Map through cart items and find matching products in local storage
      const fetchedProducts = cartItems.map((item) => {
        const product = storedProducts.find(prod => prod.id === item.productId);
  
        // If the product is found, return it with additional properties
        if (product) {
          return {
            ...product,
            quantity: item.quantity,
            image: item.image,
            variants: item.variants,
          };
        }
        return null; // Return null if product is not found
      }).filter(product => product !== null); // Filter out null products
  
      setCartProducts(fetchedProducts);
      calculateCartTotal(fetchedProducts);
    } else {
      setCartProducts([]);
      setCartTotal(0);
    }
  }, []);
  
  // Calculate the total price of the cart
  const calculateCartTotal = (products) => {
    const total = products.reduce((acc, product) => acc + product.sellingPrice * product.quantity, 0);
    setCartTotal(total);
  };

  const incrementQuantity = (index) => {
    const updatedProducts = [...cartProducts];
    updatedProducts[index].quantity += 1;
    setCartProducts(updatedProducts);
    calculateCartTotal(updatedProducts);
    updateLocalStorage(updatedProducts);
  };

  const decrementQuantity = (index) => {
    const updatedProducts = [...cartProducts];
    if (updatedProducts[index].quantity > 1) {
      updatedProducts[index].quantity -= 1;
      setCartProducts(updatedProducts);
      calculateCartTotal(updatedProducts);
      updateLocalStorage(updatedProducts);
    }
  };

  const removeProduct = (index) => {
    const updatedProducts = cartProducts.filter((_, i) => i !== index);
    setCartProducts(updatedProducts);
    calculateCartTotal(updatedProducts);
    updateLocalStorage(updatedProducts);
  };

  const updateLocalStorage = (updatedProducts) => {
    const updatedItems = updatedProducts.map((product) => ({
      productId: product.id,
      quantity: product.quantity,
      image: product.image,
      variants: product.variants,
    }));
    localStorage.setItem('cart', JSON.stringify(updatedItems));
  };

  useEffect(() => {
    if (isOpen) {
      fetchCartProducts();
    }
  }, [isOpen, fetchCartProducts]); // Include fetchCartProducts in the dependencies

  return (
    <>
      {/* Overlay div */}
      <div className={`overlay ${isOpen ? 'active' : ''}`} onClick={closeNav}></div>

      {/* Sidebar */}
      <div id="mySidenav" className={`sidenav ${isOpen ? 'open' : ''}`}>
        <div className="sidenav-div">
          <div className="drawer__title">
            <h3 className="ui2-heading">
              <span><b>Your Cart</b></span>
            </h3>
            <a href="#12" className="closebtn" onClick={closeNav}>×</a>
          </div>
        </div>

        {/* Cart Products List */}
        <div className="cart-products-list">
          {cartProducts.length === 0 ? (
            <div className="empty-cart-message" style={{ textAlign: 'center', marginTop: '25vh', marginBottom: '30vh' }}>
              {/* Empty Cart SVG and Message */}
              <p style={{ fontSize: '20px', fontWeight: '600', color: '#1a2024', marginTop: '3rem', marginBottom: '40px' }}>
                Your cart is feeling lonely
              </p>
            </div>
          ) : (
            <>
              {cartProducts.map((product, index) => (
                <div className="cart-product cart-product-index-0" key={product.id}>
                  <div className="cart-product-img">
                    <img src={product.image} alt={product.name} />
                  </div>
                  <div className="cart-product-details">
                    <div className="cart-product-title">
                      <p>{product.name}</p>
                      <img
                        src="https://cdn.shopify.com/s/files/1/0057/8938/4802/files/Group_1_93145e45-8530-46aa-9fb8-6768cc3d80d2.png?v=1633783107"
                        className="remove-cart-item"
                        onClick={() => removeProduct(index)} // Remove product on click
                        alt="remove"
                      />
                    </div>
                    <div className="cart-product-pricing">
                      <p className="cart-product-price" style={{fontFamily:'Mier Demi'}}>₹{product.sellingPrice}</p>&nbsp;
                      <span className="cart-product-mrp">₹{product.mrp}</span>
                    </div>
                    <div className="cart-product-description">
                      {Object.entries(product.variants).map(([key, value]) => (
                        <div key={key}>
                          <p className="cart-product-color" style={{fontFamily:'Mier Demi'}}>
                            <strong>{capitalizeFirstLetter(key)}:</strong>
                            {Array.isArray(value) ? (
                              <ul>
                                {value.map((item, idx) => (
                                  <li key={idx}>{item}</li>
                                ))}
                              </ul>
                            ) : (
                              <span>{value}</span>
                            )}
                          </p>
                        </div>
                      ))}
                      <div className="cart-qty-wrapper">
                        <span className="minus" onClick={() => decrementQuantity(index)}>-</span>
                        <span className="num">{product.quantity}</span>
                        <span className="plus" onClick={() => incrementQuantity(index)}>+</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="cart__footer" style={{ display: cartProducts.length === 0 ? 'none' : 'block' }}>
                <div className="cart__price__details">
                  <div className="cart__breakup__inner">
                    <div className="cart__total">
                      <span>Cart Total:</span>
                      <span className="cartTotalAmount">₹{cartTotal.toFixed(2)}</span>
                    </div>
                    <div className="shipping__total" style={{ borderBottom: '1px dashed #000' }}>
                      <span>Shipping:</span>
                      <span>FREE</span>
                    </div>
                    <div className="mc_pay__total">
                      <span>To Pay:</span>
                      <span className="cartTotalAmount">₹{cartTotal.toFixed(2)}</span>
                    </div>
                  </div>
                </div>
                <div className="cart__checkout">
                  <div className="cart__final__payment">
                    <h2 className="cart__final__price cartTotalAmount">₹{cartTotal.toFixed(2)}</h2>
                    <p className="cart__tax__text">Inclusive of all taxes</p>
                  </div>
                  <Link to="/cart" className="btn btn-dark cart__confirm__order">
                    Confirm Order
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

// Helper function to capitalize the first letter of each variant type
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default CartSidebar;
