// Loader.js
import React from 'react';
import './Loader.css'; // Import the provided CSS

const Loader = () => {
  return (
    <div class="itivvW">
    <div class="loader-container">
      <div class="loader"></div>
    </div>
  </div>
  
  );
};

export default Loader;

