import React from 'react';
import { Carousel as BootstrapCarousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const defaultImages = [
  "https://cdn.zeebiz.com/sites/default/files/styles/zeebiz_850x478/public/2021/09/27/160399-flipkar-saleflipkar-twitter.jpg?itok=u0Yll_bl",
  "https://akm-img-a-in.tosshub.com/indiatoday/images/story/202209/flipkart_sale.jpg?VersionId=hhRtWY_umgvRgd0Z21V5pWKpn3jjg4Tn"
];

const Carousel = () => {
  return (
    <div className="carousel-container">
      <div className="carousel-wrapper">
        <div className="carousel-content">
          <div className="carousel-header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12 p-0">
                  <BootstrapCarousel interval={5000} indicators={true} controls={false}>
                    {defaultImages.map((image, index) => (
                      <BootstrapCarousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={image}
                          alt={`Slide ${index + 1}`}
                          style={{
                            width: '375px',
                            height: 'auto',
                            objectFit: 'cover'
                          }}
                        />
                      </BootstrapCarousel.Item>
                    ))}
                  </BootstrapCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
