import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import CartSidebar from '../../CartSidebar/CartSidebar';

const Buttons = ({ productId, selectedVariants, activeImage }) => {
    const [isSidebarOpen, setSidebarOpen] = useState(false); // State to manage sidebar visibility
    const [cart, setCart] = useState(() => JSON.parse(localStorage.getItem('cart')) || []); // Track cart items
    const navigate = useNavigate(); // Initialize useNavigate to programmatically navigate

    const addToCart = () => {
        // Get the existing cart from localStorage
        let newCart = JSON.parse(localStorage.getItem('cart')) || [];

        // Add the product ID, selected variants, active image, and quantity 1 to the cart
        newCart.push({ productId, quantity: 1, variants: selectedVariants, image: activeImage });

        // Store the updated cart back in localStorage
        localStorage.setItem('cart', JSON.stringify(newCart));

        // Update the cart state to reflect changes
        setCart(newCart);

        console.log(`Product ${productId} added to cart with quantity 1 and variants:`, selectedVariants);
        console.log(newCart);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    // Function to close sidebar
    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    const buyNow = () => {
        // Add the product to the cart in the same way as the addToCart function
        let newCart = JSON.parse(localStorage.getItem('cart')) || [];

        // Add the product ID, selected variants, active image, and quantity 1 to the cart
        newCart.push({ productId, quantity: 1, variants: selectedVariants, image: activeImage });

        // Store the updated cart back in localStorage
        localStorage.setItem('cart', JSON.stringify(newCart));

        // Update the cart state
        setCart(newCart);

        console.log(`Product ${productId} added to cart with quantity 1 for Buy Now with variants:`, selectedVariants);

        // Navigate to the cart route
        navigate('/cart');
    };

    return (
        <>
            <div className="button-container flex">
                <button
                    className="buynow-button buynow-button-white product-page-buy add_cart"
                    onClick={() => {
                        addToCart();
                        toggleSidebar();
                    }}
                >
                    
                    Go to Cart
                </button>
                <button
                    className="buynow-button product-page-buy buy_now"
                    onClick={buyNow}
                >
                 
                    Buy Now
                </button>
            </div>
            <CartSidebar isOpen={isSidebarOpen} closeNav={closeSidebar} cart={cart} /> {/* Pass updated cart */}
        </>
    );
};

export default Buttons;
