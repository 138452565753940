import { useEffect } from "react";
import API_URL from './apiconfig';

const PixelInjector = () => {
  useEffect(() => {
    const fetchPixelCode = async () => {
      try {
        // Check if pixel code exists in local storage
        const storedPixelCode = localStorage.getItem('pixelCode');
        if (storedPixelCode) {
          console.log("Using pixel code from local storage.");
          injectPixelCode(storedPixelCode);
          return; // Exit the function if we are using stored code
        }

        const response = await fetch(`${API_URL}/homebanner/pixel`);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Fetch the pixel data
        const data = await response.json();
        console.log("Fetched pixel data:", data);

        // Assuming the response is an array and contains a single string element
        const pixelCode = data[0]; // Get the pixel code from the array

        // Check if pixelCode is null or empty
        if (pixelCode && pixelCode.trim() !== "") {
          // Store the pixel code in local storage
          localStorage.setItem('pixelCode', pixelCode);
          injectPixelCode(pixelCode);
        } else {
          console.log("No valid pixel code found; nothing will be added.");
        }
      } catch (error) {
        console.error("Error fetching the pixel code:", error);
      }
    };

    // Function to inject the pixel code into the head
    const injectPixelCode = (pixelCode) => {
      // Check if the pixel code already exists in the head
      const existingCode = document.querySelector('script[data-pixel-code]');

      if (!existingCode) {
        // Create a temporary div to parse the pixel code
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = pixelCode; // Set the innerHTML to the pixel code

        // Append <script> elements found in the code to the head
        const scripts = tempDiv.getElementsByTagName("script");
        for (let script of scripts) {
          const newScript = document.createElement("script");
          newScript.type = "text/javascript";
          newScript.setAttribute("data-pixel-code", "true");
          newScript.innerHTML = script.innerHTML; // Set the script's inner HTML
          document.head.appendChild(newScript); // Append to head
        }

        // Append <noscript> elements as well
        const noscripts = tempDiv.getElementsByTagName("noscript");
        for (let noscript of noscripts) {
          const newNoscript = document.createElement("noscript");
          newNoscript.innerHTML = noscript.innerHTML; // Set the noscript's inner HTML
          document.head.appendChild(newNoscript); // Append <noscript> to head
        }

        console.log("Pixel scripts and noscripts added to head.");
      } else {
        console.log("Pixel scripts already exist in the head.");
      }
    };

    fetchPixelCode();
  }, []);

  return null; // No UI output required
};

export default PixelInjector;
