import React from 'react';
import './Offer.css';

const defaultOfferBanner = 'Buy 2 Get 1 Free (Add 3 items to cart)'; // Default offer text
const defaultBannerImage = 'https://images.meesho.com/images/widgets/OY6J5/xwgyl_800.webp'; // Default banner image
const staticTime = 4500; // Static time in seconds for display

const Offer = () => {
    // Format time statically
    const formatTime = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = seconds % 60;
        return `${h.toString().padStart(2, '0')}h : ${m.toString().padStart(2, '0')}m : ${s.toString().padStart(2, '0')}s`;
    };

    return (
        <>
            <div className="menu">
                <div className="menu__item">
                    <div className="marquee">
                        <div className="marquee__inner">
                            <span>{defaultOfferBanner}</span>
                            <span>&nbsp;</span>
                            <span>{defaultOfferBanner}</span>
                            <span>&nbsp;</span>
                            <span>{defaultOfferBanner}</span>
                            <span>&nbsp;</span>
                            <span>{defaultOfferBanner}</span>
                            <span>&nbsp;</span>
                            <span>{defaultOfferBanner}</span>
                        </div>
                    </div>
                </div>
            </div>
            <img 
                src={defaultBannerImage} 
                alt="Offer banner" 
                className="w-100 mb-2" 
                style={{ width: '375px', height: '58px', objectFit: 'cover' }} 
            />
            <div color="white" className="sc-bcXHqe eCQLMa">
                <div className="sc-bcXHqe fctQgp OfferTimerSubtitle__StyledSubtitleContainer-sc-9svgo6-0 JgdIi OfferTimerSubtitle__StyledSubtitleContainer-sc-9svgo6-0 JgdIi" color="white">
                    <p style={{ fontWeight: 'book', color: 'greyT1', fontFamily: 'Mier Book' }} className="sc-dkrFOg kZrHuA">
                        Flipkart Deals
                        <span>
                            <svg width="19" height="22" fill="none" xmlns="http://www.w3.org/2000/svg" iconsize="20" className="sc-bcXHqe bMWbfM">
                                <mask id="deal_svg__a" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="17" height="22">
                                    <path d="M5.198.795a.73.73 0 0 0-.699.522l-2.621 8.858a1.458 1.458 0 0 0 1.568 1.862l6.018-.708L6.835 19.8a1.089 1.089 0 0 0 1.944.929l8.174-12.206c.698-1.043-.16-2.423-1.405-2.257l-5.033.671 1.724-5.183a.73.73 0 0 0-.692-.96H5.198Z" fill="#fff"></path>
                                </mask>
                                <g mask="url(#deal_svg__a)">
                                    <path fill="#E11900" d="M-.208.947H18.75v21.875H-.208z"></path>
                                </g>
                            </svg>
                        </span>
                    </p>
                    <div data-testid="offer-wrapper" className="OfferTimerstyled__StyledMediumOfferWrapper-sc-1otdyo0-0 eVLdcs">
                        <img 
                            alt="Offer clock icon" 
                            loading="lazy" 
                            width="16" 
                            height="16" 
                            decoding="async" 
                            data-nimg="1" 
                            style={{ color: 'transparent', marginRight: '4px' }} 
                            src="https://images.meesho.com/images/offer-widget-group-icon/T1W36/7tepb.png" 
                        />
                        <span 
                            style={{ fontSize: '13px', fontWeight: 'demi' }} 
                            data-testid="offer-timer-text" 
                            className="sc-dkrFOg bZxmrh OfferTimer__Subtitle2Styled-sc-dfvdiq-1 jFYRpH" 
                            color="pinkBase"
                        >
                            {formatTime(staticTime)}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Offer;
