import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Search from '../Search/Search';
import Category from './Category/Category';
import Banner from './Banners/Banner';
import Offer from './Offer/Offer';
import Loader from '../Loader/Loader'; // Make sure to import your loader component
import BottomNavbar from './BottomNavBar/BottomNavBar';
import API_URL from '../../apiconfig';
import './Products/Products.css';

const Home = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [products, setProducts] = useState(() => {
    const storedProducts = localStorage.getItem('products');
    return storedProducts ? JSON.parse(storedProducts) : [];
  });
  const [assuredImage, setAssuredImage] = useState(() => {
    const storedImage = localStorage.getItem('assuredImage');
    return storedImage || '/assets/assured.png';
  });
  const [loading, setLoading] = useState(true); // Keep the loading state

  useEffect(() => {
    const fetchData = async () => {
      // If products are already in localStorage, don't fetch
      if (products.length > 0) {
        setLoading(false);
        return;
      }

      try {
        const [productsResponse, assuredImageResponse] = await Promise.all([
          fetch(`${API_URL}/products`),
          fetch(`${API_URL}/homebanner/assured_image`)
        ]);

        const productsData = await productsResponse.json();
        const assuredImageData = await assuredImageResponse.json();

        // Set products and assured image
        setProducts(productsData);
        localStorage.setItem('products', JSON.stringify(productsData));
        
        const image = assuredImageData[0] || '/assets/assured.png';
        setAssuredImage(image);
        localStorage.setItem('assuredImage', image);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [products.length]);

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const filteredProducts = products
    .filter((product) => product.name.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => a.id - b.id);

  return (
    <>
      <Navbar />
      <Search onSearch={handleSearch} />
      <Category />
      <Banner />
      <Offer />

      <div className="products" id="products">
        <div className="product-list">

        
            
          {loading ? ( // Show loading graphic if loading
            <Loader />
          ) : filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <Link key={product._id} to={`/productDetails/${product.id}`} className="product-card" data-id={product.id}>
                <div className="product-img">
                  <img src={product.image} alt={product.name} loading="lazy" /> {/* Lazy loading images */}
                </div>
                <div className="product-details">
                  <h3 className="product-name">{product.name}</h3>
                  <div className="product-price">
                    <span className="off-percentage m-0">
                      {Math.round(((product.mrp - product.sellingPrice) / product.mrp) * 100)}% off
                    </span>
                    <span className="mrp-price line-through">₹{product.mrp}</span>
                  </div>
                  <span className="NewProductCardstyled__OfferPill-sc-6y2tys-31 iMEkWH">
                    <span className="sell-price">₹{product.sellingPrice}</span>
                    <img
                      src={assuredImage || '/assets/trust.svg'}
                      alt="trust icon"
                      className="sc-bcXHqe fUjwpe"
                      style={{ height: '20px', objectFit: 'cover' }}
                    />
                  </span>
                  <span style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <button className="limitedDeal btn">Limited Time Deal</button>
                  </span>
                  <p className="free-delivery">Free Delivery in 2 Days</p>
                  <div className="sc-kDvujY jTuxux NewProductCardstyled__RatingsRow-sc-6y2tys-8 heKerA" color="white">
                    <div className="NewProductCardstyled__RatingSection-sc-6y2tys-9 fyvrGC">
                      <span label={product.rating} className="sc-fbYMXx jUvjLH">
                        <span fontSize="13px" fontWeight="demi" color="#ffffff" className="sc-gswNZR jrrvJf">
                          {product.rating}
                        </span>
                        <img src="/assets/star.svg" alt="star icon" className="sc-bcXHqe cyUZOu" />
                      </span>
                      <span fontSize="11px" fontWeight="book" color="greyT2" className="sc-gswNZR gTFgDk NewProductCardstyled__RatingCount-sc-6y2tys-21 jZyLzI">
                        ({product.ratingNumber})
                      </span>
                    </div>
                    <img
                      src={assuredImage || '/assets/trust.svg'}
                      alt="trust icon"
                      className="sc-bcXHqe fUjwpe"
                      style={{ width: '55px', height: '20px', objectFit: 'cover' }}
                    />
                  </div>
                </div>
              </Link>

            ))
          ) : (
            <p className="noProduct">No products found.</p>
          )}
        </div>
      </div>
      <BottomNavbar />
    </>
  );
};

export default Home;
